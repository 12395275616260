$icon-font-path: "../../fonts/";
// node:scss
@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endnode

$main-bcg-color: #3498DB;
$top-up-btn-color: #3aaa35;

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
}
.margin-top {
    margin-top:20px;
}

.margin-bottom {
    margin-bottom:20px;
}

.main-box {
    background-color: $main-bcg-color;
}

.send-page {
    background-color: $main-bcg-color;
    min-height: 100vh;
}

.top-up-btn {
    color: white !important;
    background-color: $top-up-btn-color;
    border: 2px solid $top-up-btn-color;
}

del {
    text-decoration: none;
    position: relative;
}

del:after {
    content: ' ';
    font-size: inherit;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    top: 40%;
    bottom: 40%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

#footer{
    text-align: center;
}
.nounderline:hover{text-decoration: none}
